/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StrategieUmsaetzeKumuliert,
  UmsaetzeKumuliert,
} from '../models/index';
import {
    StrategieUmsaetzeKumuliertFromJSON,
    StrategieUmsaetzeKumuliertToJSON,
    UmsaetzeKumuliertFromJSON,
    UmsaetzeKumuliertToJSON,
} from '../models/index';

export interface StatistikKumuliertRequest {
    prognoseGruppeId: number;
    filter: string;
}

export interface StatistikKumuliertStrategieRequest {
    prognoseGruppeId: number;
    filter: string;
}

/**
 * StatistikApi - interface
 * 
 * @export
 * @interface StatistikApiInterface
 */
export interface StatistikApiInterface {
    /**
     * 
     * @summary Gibt kumulierte Soll- und Ist-Werte für einen angegebenen RSQL-Filter zurück.
     * @param {number} prognoseGruppeId 
     * @param {string} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatistikApiInterface
     */
    statistikKumuliertRaw(requestParameters: StatistikKumuliertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UmsaetzeKumuliert>>>;

    /**
     * Gibt kumulierte Soll- und Ist-Werte für einen angegebenen RSQL-Filter zurück.
     */
    statistikKumuliert(requestParameters: StatistikKumuliertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UmsaetzeKumuliert>>;

    /**
     * 
     * @summary Gibt kumulierte Strategie-Umsätze für einen angegebenen RSQL-Filter zurück.
     * @param {number} prognoseGruppeId 
     * @param {string} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatistikApiInterface
     */
    statistikKumuliertStrategieRaw(requestParameters: StatistikKumuliertStrategieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StrategieUmsaetzeKumuliert>>>;

    /**
     * Gibt kumulierte Strategie-Umsätze für einen angegebenen RSQL-Filter zurück.
     */
    statistikKumuliertStrategie(requestParameters: StatistikKumuliertStrategieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StrategieUmsaetzeKumuliert>>;

}

/**
 * 
 */
export class StatistikApi extends runtime.BaseAPI implements StatistikApiInterface {

    /**
     * Gibt kumulierte Soll- und Ist-Werte für einen angegebenen RSQL-Filter zurück.
     */
    async statistikKumuliertRaw(requestParameters: StatistikKumuliertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UmsaetzeKumuliert>>> {
        if (requestParameters.prognoseGruppeId === null || requestParameters.prognoseGruppeId === undefined) {
            throw new runtime.RequiredError('prognoseGruppeId','Required parameter requestParameters.prognoseGruppeId was null or undefined when calling statistikKumuliert.');
        }

        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling statistikKumuliert.');
        }

        const queryParameters: any = {};

        if (requestParameters.prognoseGruppeId !== undefined) {
            queryParameters['prognoseGruppeId'] = requestParameters.prognoseGruppeId;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/statistik/kumuliert`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UmsaetzeKumuliertFromJSON));
    }

    /**
     * Gibt kumulierte Soll- und Ist-Werte für einen angegebenen RSQL-Filter zurück.
     */
    async statistikKumuliert(requestParameters: StatistikKumuliertRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UmsaetzeKumuliert>> {
        const response = await this.statistikKumuliertRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt kumulierte Strategie-Umsätze für einen angegebenen RSQL-Filter zurück.
     */
    async statistikKumuliertStrategieRaw(requestParameters: StatistikKumuliertStrategieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<StrategieUmsaetzeKumuliert>>> {
        if (requestParameters.prognoseGruppeId === null || requestParameters.prognoseGruppeId === undefined) {
            throw new runtime.RequiredError('prognoseGruppeId','Required parameter requestParameters.prognoseGruppeId was null or undefined when calling statistikKumuliertStrategie.');
        }

        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling statistikKumuliertStrategie.');
        }

        const queryParameters: any = {};

        if (requestParameters.prognoseGruppeId !== undefined) {
            queryParameters['prognoseGruppeId'] = requestParameters.prognoseGruppeId;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/statistik/kumuliert/strategie`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StrategieUmsaetzeKumuliertFromJSON));
    }

    /**
     * Gibt kumulierte Strategie-Umsätze für einen angegebenen RSQL-Filter zurück.
     */
    async statistikKumuliertStrategie(requestParameters: StatistikKumuliertStrategieRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<StrategieUmsaetzeKumuliert>> {
        const response = await this.statistikKumuliertStrategieRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
